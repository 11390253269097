import React from 'react';
import ReactDOM from 'react-dom';
import {BrowserRouter as Router} from 'react-router-dom'
import './index.css';
import {Index} from "./components/app";

ReactDOM.render(
  <React.StrictMode>
      <Router>
          <Index />
      </Router>
  </React.StrictMode>,
  document.getElementById('root')
);
