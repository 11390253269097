import {createUseStyles} from "react-jss";

export const useStyles = createUseStyles({
    page: {
        padding: '8px 32px',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    text: {
        textAlign: 'center',
        marginBottom: '31px'
    },
    input: {
        width: '100%',
    },
    forgot: {
        marginTop: '32px',
        textDecoration: 'none',
        borderBottom: '1px solid #4663B7',
        color: '#4663B7',
    },
    button: {
        marginTop: '32px',
        padding: '12px',
        width: '180px',
        '& p': {
            fontWeight: 600,
            lineHeight: '22px',
        }
    },
    eyeIcon: {
        marginTop: 7,
    },
    error: {
        marginTop: '8px',
        color: '#FF4D2B',
        lineHeight: '16px',
        fontWeight: 'normal',
    }
})