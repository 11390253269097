import React, {useEffect, useState} from "react";
import { Switch, Route} from "react-router-dom"
import {ScrollToTop} from "../scroll_to_top";
import {Email} from "../../pages/email";
import {MainContent} from "../main_content";
import {Header} from "../header";
import {useHistory} from "react-router";
import {Password} from "../../pages/password";
import {Success} from "../../pages/success";
import {Deny} from "../../pages/deny";

export const Index = () => {

    const titles: any = {
        '/': 'What’s your email?',
        '/enter-password': 'Enter your password',
        '/create-password': 'Create a password'
    }

    const history = useHistory()

    const [title, setTitle] = useState('What’s your email?')

    const [email, setEmail] = useState('')

    const onChangeEmail = (e: any) => {
        setEmail(e.target.value)
    }

    useEffect(() => {
        setTitle(titles[history.location.pathname])
    }, [history.location.pathname])

    return (
        <>
            <ScrollToTop />
            <MainContent>
                {Object.keys(titles).includes(history.location.pathname) ? <Header text={title} /> : null}
                <Switch>
                    <Route path={'/'} exact>
                        <Email email={email} onChangeEmail={onChangeEmail}/>
                    </Route>
                    <Route path={'/create-password'} exact>
                        <Password email={email} location={history.location.pathname}/>
                    </Route>
                    <Route path={'/enter-password'} exact>
                        <Password email={email} location={history.location.pathname}/>
                    </Route>
                    <Route path={'/success'} exact>
                        <Success email={email}/>
                    </Route>
                    <Route path={'/deny'} exact>
                        <Deny />
                    </Route>
                </Switch>
            </MainContent>
        </>
    )
}