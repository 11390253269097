import {WrapperApi} from "./wrapper.api";

class PasswordApi extends WrapperApi{
    recovery(data: any): Promise<any> {
        return this.post('/password/recovery', data)
    }

    reset(data: any): Promise<any> {
        return this.post('/password/reset', data)
    }
}

export const PasswordApiProvider = new PasswordApi();
