import {createUseStyles} from "react-jss";

export const useStyles = createUseStyles({
    page: {
        padding: '8px 32px',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    text: {
        textAlign: 'center',
        marginBottom: '31px'
    },
    input: {
        width: '100%',
    },
    button: {
        marginTop: '32px',
        padding: '12px',
        width: '180px',
        '& p': {
            fontWeight: 600,
            lineHeight: '22px',
        }
    }
})