import {createUseStyles} from "react-jss";

export const useStyles = createUseStyles({
    page: {
        height: (props: number) => `${props}px`,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        padding: '0 32px',
    },
    image: {
        width: 237,
        marginBottom: 42,
    },
    text: {
        padding: '18px 16px',
        background: '#F0F0FF',
        marginBottom: 80,
        textAlign: 'center',
    }
})