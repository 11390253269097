import React, {useState} from "react";
import {PrimaryButton, Typography, ValidatedInput} from "@ruby-labs/ruby-web-common";
import {useStyles} from "./styles";

import { PasswordApiProvider } from "../../api/password.api";

export const Email = (props: any) => {

    const { page, text, input, button } = useStyles()

    const {email, onChangeEmail} = props

    const [error, setError] = useState(true)

    const onError = (error: boolean) => {
        setError(error)
    }

    const handleSubmitEmail = async () => {
        const res = await PasswordApiProvider.recovery({ email })
        if (res?.data?.statusCode === 200) {
        }
    }

    return (
        <div className={page}>
            <Typography variant={'body1'} className={text}>
                We need it to look up your account or create a new one.
            </Typography>
            <ValidatedInput
                errorDelay
                errorDelayTime={400}
                validationQuery={{
                    type: {
                        value: 'email',
                        errorMessage: 'Please enter correct email'
                    }
                }}
                onChange={onChangeEmail}
                value={email}
                rootClassName={input}
                onError={onError}
                label={'Your email'}
            />
            <PrimaryButton
                className={button}
                label={'Next'}
                disabled={error}
                onClick={handleSubmitEmail}
            />
        </div>
    )
}