const axios = require('axios');

axios.defaults.timeout = 10000;
axios.defaults.headers.common['Content-Type'] = 'application/json';

const instance = axios.create({
    baseURL: 'https://some-domain.com/api/',
});

const headers = new Headers({
    "Access-Control-Allow-Origin": "*",
    "Content-Type": "application/json",
    "Cache-Control": "no-cache",
});

export class WrapperApi {
    get(action: string, params?: any, version?: number): Promise<any> {
        const search_params = new URLSearchParams();

        for (let key in params) search_params.append(key, params[key]);

        const url = `${process.env.REACT_APP_API_URL}/v${version || '1'}/${action}?${search_params}`;
        const apiParams = { headers };

        return fetch(url, apiParams).then((response) => response.json());
    }

    post(action: string, data: any, apiVersion = 1): Promise<any> {
        try {
            const body = JSON.stringify(data);
            console.log(body)
            const url = `${process.env.REACT_APP_API_URL}/v${apiVersion}${action}`;
            return instance.post(url, body);
        } catch (err) {
            return Promise.reject(err.message);
        }
    }
}
