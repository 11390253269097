import React from "react";
import {Typography} from "@ruby-labs/ruby-web-common";
import {useHistory} from "react-router";
import {useStyles} from "./styles";
import goBack from '../../assets/svg/arrowLeft.svg'

export const Header = (props: any) => {
    const history = useHistory()

    const { text } = props

    const { header, textStyle } = useStyles()

    const onSubmit = () => {
        history.goBack()
    }

    return (
        <header className={header}>
            <img src={goBack} alt="go back" onClick={onSubmit}/>
            <Typography variant="h2" className={textStyle}>
                { text }
            </Typography>
        </header>
    )
}