import React from "react";
import denied from '../../assets/svg/denied.svg'
import {PrimaryButton, Typography} from "@ruby-labs/ruby-web-common";
import {useStyles} from "./styles";

export const Deny = () => {

    const { page, image, text } = useStyles(window.innerHeight)

    return (
        <div className={page}>
            <img src={denied} alt="success" className={image}/>
            <Typography variant={'body1'} className={text}>
                This link has expired and it's no longer valid for security reasons. Open the Able app and click on 'Forgot Password' again to get a new link.
            </Typography>
            <PrimaryButton label={'Open Able'} />
        </div>
    )
}