import React, {useState} from 'react'
import {Link} from "react-router-dom";
import {Input, PrimaryButton, Typography} from "@ruby-labs/ruby-web-common";
import {useStyles} from "./styles";
import closeEye from '../../assets/svg/closeEye.svg'
import openEye from '../../assets/svg/openEye.svg'

export const Password = ({location, email}: any) => {

    const {
        page, text, input,
        button, forgot, error,
        eyeIcon,
    } = useStyles()

    const [password, setPassword] = useState('')
    const [type, setType] = useState(true)
    const [validate, setValidate] = useState(true)

    const onChange = (e: any) => {
        setPassword(e.target.value)
        validatePassword(e.target)
    }

    const validatePassword = (password: any) => {
        const validate = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{6,20}$/
        if(password.value.match(validate)) {
            setValidate(true)
        } else {
            setValidate(false)
        }
    }

    const toggleShowPassword = () => {
        setType(prev => !prev)
    }

    return (
        <div className={page}>
            <Typography variant={'body1'} className={text}>
                {location === '/create-password' ?
                'Looks like it’s your first time! Don’t worry, just create a password for your account and we’re all set!' :
                `Welcome back ${email}! Please enter your password to access your account.`}
            </Typography>
            <Input
                onChange={onChange}
                value={password}
                rootClassName={input}
                label={'Your password'}
                type={type ? 'password' : 'text'}
                adornmentEnd={<img className={eyeIcon} src={type ? closeEye : openEye} alt="toggle show password" onClick={toggleShowPassword}/>}
            />
            {validate
                ? null
                : <Typography variant={'label'} className={error}>Enter a valid password. Your password must contain at least 6 characters, including a lower-case letter, an upper-case letter, and a number.</Typography>}

            {location !== '/create-password' ? <Link to={'/'} className={forgot}>Forgot your password?</Link> : null}
            <PrimaryButton
                className={button}
                label={'Next'}
                disabled={!validate}
            />
        </div>
    )
}