import {createUseStyles} from "react-jss";

export const useStyles = createUseStyles({
    header: {
        padding: 24,
        display: 'flex',
        alignItems: 'center',
    },
    textStyle: {
        width: '100%',
        marginLeft: '-24px',
        textAlign: 'center'
    }
})