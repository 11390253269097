import React from "react"
import {useStyles} from "./styles";

export const MainContent = ({children}: any) => {

    const { mainContent } = useStyles()

    return (
        <div className={mainContent}>
            {children}
        </div>
    )
}