import React from "react";
import success from '../../assets/svg/success.svg'
import {PrimaryButton, Typography} from "@ruby-labs/ruby-web-common";
import {useStyles} from "./styles";

export const Success = ({email}: any) => {

    const { page, image, text } = useStyles(window.innerHeight)

    return (
        <div className={page}>
            <img src={success} alt="success" className={image}/>
            <Typography variant={'body1'} className={text}>
                Your password for {email} has been successfully changed!
            </Typography>
            <PrimaryButton label={'Next'} />
        </div>
    )
}